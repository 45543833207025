html, body, #root{
    height: 100%;
    width: 100%;
    background: #fff;
}


@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    html:not([data-scale]) .am-button {
        border-radius: 100px;
    }
    html:not([data-scale]) .icon-button{
        border-radius: 6px!important;
    }

    html:not([data-scale]) .am-button::before,
    html:not([data-scale]) .am-button-ghost.am-button-active::before{
        border: none;
    }
}

.seller-logo{
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 3px solid #FFFFFF;
    background: black;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    overflow: hidden;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    width: 90px;
    height: 90px;
    border-radius: 50%;
}

.am-search-cancel{
    color: #F4743D;
}

.main-button-card{
    padding: 20px;
}

.accordion-child{
    border-top: none;
    margin-left: 30px;
}

.block-logo-seller{
    width: auto;
    height: 100%;
}

.icon-button{
    height: 48px;
    width: 48px;
    border-radius: 6px!important;
    -webkit-user-select: none; /* Safari */
    user-select: none;
}

.am-button-ghost.am-button-disabled{
    border: none;
}

.am-button > .am-button-icon{
    margin: 0;
}

.main-card-text{
    font-size: 20px;
    color: #333333;
}

.action-button{
    text-align: center;
    z-index: 99;
    display: inherit;
    position: fixed;
    bottom: 2vh;
    right: 2vh;
    width: 48px;
    height: 48px;
    border-radius: 100px;
    background: #F4743D;
    color: #fff;
}

.action-button > span{
    margin: 9px;
}

.action-button img{
    width: 30px;
    height: 30px;
}

.action-button a{
    color: #fff;
    margin: auto;
}

.flex-container.auth{
    padding: 10vw;
}

.main-block{
    background-image: url(shared/img/background.png);
    background-size: 100% 50%;
    background-repeat: no-repeat;
    background-position: 100% 0;
}

.main-block.client{
    background-size: 100% 90%;
}

.main-block-header{
    padding: 8vw;
}

.block-text-header{
    color: #FFFFFF;
}

.block-text-fio{
    font-size: 25px;
}

.block-text-company{
    font-size: 20px;
}

.action-group-block{
    bottom: 0;
    vertical-align: bottom;
    display: block;
}

.button-action > .am-button{
    width: 100%;
    height: 100%;
    background: #FDFDFD;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px!important;
}

.find-client-img{
    background-image: url(shared/img/userProfile.png);
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 50% 0;
}

.title-block{
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 29px;
    text-align: center;
    color: #333333;
}

.subtitle-block{
    color: #333333;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.15px;

    mix-blend-mode: normal;
}

.ebDhOV{
    margin: auto!important;
    width: 100%!important;
    padding: 10px!important;
}

.ebDhOV > div input{
    width: 30px;
    height: 30px;
    border: 2px solid #F4743D!important;
    border-radius: 10px!important;
}

.am-checkbox.am-checkbox-checked .am-checkbox-inner {
    border-color: #40A820;
    background: #40A820;
}

.am-modal-button-group-v .am-modal-button{
    color: #FF7E42;
}

.main-nav-bar{
    color: #FF7E42;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.24);
}

.pay-button{
    position: fixed;
    padding: 0 10px 0 10px;
    bottom: 2vh;
    right: 70px;
}

.coupon-button{
    position: fixed;
    padding: 0 10px 0 10px;
    bottom: 2vh;
    right: 260px;
}

.am-button {
    border: none;
    border-radius: 100px;
    color: #F4743D;
}

.am-button-primary{
    background: #F4743D;
    color: #fff;
}

.am-modal-button-group-h .am-modal-button{
    color: #F4743D;
}

.am-button-primary.am-button-active{
    background: #e67c4e;
}

.am-button-ghost.am-button-active{
    color: #F4743D;
    border: 1px solid #F4743D;
}

.am-list-item.am-input-item {
    border-bottom: 1px solid #F4743D;
}

.auth-logo-block{
    text-align: center;
}

.am-list-item .am-input-clear-active{
    background-color: #F4743D;
}

.am-modal-popup-slide-up {
    max-height: 100%;
}

.basic-price{
    padding-right: 10px;
    font-size: 18px;
    text-decoration: line-through;
}

.price-with-discount{
    font-size: 20px;
    color: #FF2D2D;
}

.am-number-keyboard-wrapper table tr .am-number-keyboard-item.keyboard-confirm.am-number-keyboard-item-disabled,
.am-number-keyboard-wrapper table tr .am-number-keyboard-item.keyboard-confirm.am-number-keyboard-item-active,
.am-number-keyboard-wrapper table tr .am-number-keyboard-item.keyboard-confirm{
    background-color: #F4743D;
}

.am-list-item .am-list-line .am-list-content{
    white-space: normal;
}

.custom-action-sheet .am-action-sheet-button-list-item {
    height: 100%;
}

.main-button-card.logout-button{
    background-color: #e65a27;
    color: #fff;
    font-size: 20pt;
}

.action-accordion{
    border-top: 0;
}

.action-accordion .am-accordion-header{
    color: #888!important
}

.sub-description {
    font-style: italic;
}

.wrapper-stepper-goods{
    display: flex;
}

.stepper-goods{
    margin-left: auto;
    --border: '1px solid #f5f5f5'!important;
    --height: 46px!important;
}

.adm-stepper-minus svg, .adm-stepper-plus svg{
    color: #e65a27;
}

.adm-button.adm-button-shape-rectangular {
    border-radius: 8px;
}

.adm-stepper .adm-stepper-input{
    background-color: transparent;
    min-width: 50px;
}

.label-mark{
    color: #333333;
    padding: 10px;
    font-size: 12pt;
}

.add-goods-btn{
    margin: 10px;
}
